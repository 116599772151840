import React from "react"
import { withStyles } from "@material-ui/core/styles"
import {Grid} from "@material-ui/core"

const styles = theme => ({
  container: {
    minHeight: '100vh',
    flexGrow: 1,
    padding: 20,
  },
  section: {
    marginTop: 20,
    maxWidth: 725,
  }
});

const InfoLayout = ({classes, children, ...other}) => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.container}
    >
    <section className={classes.section}>
      {children}
    </section>
    </Grid>
  );
}

export default withStyles(styles)(InfoLayout);